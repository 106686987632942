import React, { useCallback, useRef } from "react"

import "bootstrap/dist/css/bootstrap.min.css"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link, navigate } from "gatsby" // import Swiper JS

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-cards"
import HeroSwiper from "../components/hero-swiper/HeroSwiper"

import { Container } from "react-bootstrap"
import useAuth from "../hooks/useAuth"
import Feature1 from "../components/homepage/Feature1"
import Feature2 from "../components/homepage/Feature2"
import Feature3 from "../components/homepage/Feature3"
import * as css from "./index.module.css"
import MetaTags from "../components/MetaTags"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import AnimatedBackground from "../components/hero-swiper/AnimatedBackground"
import HeaderLandingPage from "../components/HeaderLandingPage"
import Testimonial from "../components/homepage/Testimonial"
import AsSeenIn from "../components/homepage/AsSeenIn"
import toast, { Toaster } from "react-hot-toast"

// markup
const IndexPage = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [canvasVisibility, setCanvasVisibility] = React.useState("none")
  const [totalMember, setTotalMember] = React.useState(9012)
  const [totalIdCard, setTotalIdCard] = React.useState(9012)
  const [mostRegisteredSpecies, setMostRegisteredSpecies] = React.useState('Kucing')
  const [mostRegisteredSpeciesNumber, setMostRegisteredSpeciesNumber] = React.useState(7000)
  const [mostRegisteredCity, setMostRegisteredCity] = React.useState('Kota Bandung')
  const [mostRegisteredCityNumber, setMostRegisteredCityNumber] = React.useState(500)
  const { isAuthenticated, isLoading } = useAuth()


  // Redirect after login
  React.useEffect(() => {
    if (typeof location !== 'undefined') {
      // If error during google login
      if (typeof location.search !== 'undefined' && location.search.includes('error=google_login_failed')) {
        toast.error("Login gagal, klik izinkan saat login menggunakan Google.", {
          duration: 10000,
        })
        return;
      }

      // If next page exist
      var redirectUrl = decodeURIComponent(location.search.replace('?next=', ''));
      const regex = /^(.*?id=\d+\b)/;
      const match = redirectUrl.match(regex);

      if (match) {
        // Save to localStorage
        console.log('redirectAfterLogin', match[1])
        if (isAuthenticated === false) {
          toast.success("Pembayaran Berhasil, login untuk melanjutkan transaksi.", {
            duration: 10000,
          })
        }
        if (typeof window !== 'undefined') {
          localStorage.setItem('redirect_after_login', match[1])
        }
      }
    }

  }, [])

  React.useEffect(() => {
    // Redirect to dashboard if already login
    if (isAuthenticated) {
      // Redirect to specific page if `redirect_after_login` set 
      if (typeof window !== 'undefined') {
        const redirectAfterLogin = localStorage.getItem('redirect_after_login')
        if (redirectAfterLogin) {
          // Remove from localStorage
          localStorage.removeItem('redirect_after_login')

          // Redirect to dashboard
          navigate(redirectAfterLogin)
        } else {
          navigate("/dashboard")
        }
      } else {
        navigate("/dashboard")
      }
    }
  }, [isAuthenticated])

  return (
    <>
      <MetaTags
        metaTitle="KTP Anabul"
        metaPath="/"
        metaPreviewImage={metaPreviewImage}
      />
      <div><Toaster /></div>

      <HeaderLandingPage />

      {!isAuthenticated && (
        <>
          <AnimatedBackground />

          <HeroSwiper swiperOnly={false}
            totalMember={totalMember}
            totalIdCard={totalIdCard}
            mostRegisteredSpecies={mostRegisteredSpecies}
            mostRegisteredSpeciesNumber={mostRegisteredSpeciesNumber}
            mostRegisteredCity={mostRegisteredCity}
            mostRegisteredCityNumber={mostRegisteredCityNumber}
          />

          <AsSeenIn />
          <div className={css.features}>
            <Feature1 />
            <Feature2 />
            <Feature3 />
            <Testimonial
              setTotalMember={setTotalMember}
              setTotalIdCard={setTotalIdCard}
              setMostRegisteredSpecies={setMostRegisteredSpecies}
              setMostRegisteredSpeciesNumber={setMostRegisteredSpeciesNumber}
              setMostRegisteredCity={setMostRegisteredCity}
              setMostRegisteredCityNumber={setMostRegisteredCityNumber}
            />
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default IndexPage
