import React, {useEffect, useState} from "react"

import {Link, navigate} from "gatsby"
import {Navbar, Container, Col, Row, Nav} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"
import {Helmet} from "react-helmet"
import * as styles from "../../../login.module.css"
import * as globalCss from "../../../../components/styles/global.module.css"
import log from "../../../../components/log"
import axios from "../../../../api/axios"

const isDebug = (process.env.APP_DEBUG == 'true')

const Callback = () => {
    // States
    const [isVerifyCodeLoading, setIsVerifyCodeLoading] = useState(true);
    const [isVerifyCodeSuccess, setIsVerifyCodeSuccess] = useState(false);
    const [isVerifyCodeResponseMessage, setIsVerifyCodeResponseMessage] = useState('');

    // Functions
    const postValidateLoginCode = async (code: string) => {
        // Call API
        setIsVerifyCodeLoading(true)
        let endpoint = '/api/auth/google/verify'
        if (isDebug) {
            log("debug", "Calling API.", {
                endpoint: endpoint,
                code: code,
            })
        }

        try {
            const res = await axios.post(
                endpoint,
                {
                    code: code,
                }
            )

            if (res.status === 200) {
                setIsVerifyCodeSuccess(true)

                // Save token to `localStorage`
                setIsVerifyCodeResponseMessage('Berhasil login, mengarahkan ke halaman dasbor');
                if (typeof window !== "undefined") {
                    localStorage.setItem("access_token", res?.data?.data.access_token)
                    localStorage.removeItem("last_login_request")
                }

                // Redirect to specific page if `redirect_after_login` set 
                if (typeof window !== 'undefined') {
                    const redirectAfterLogin = localStorage.getItem('redirect_after_login')
                    if (redirectAfterLogin) {
                        // Remove from localStorage
                        localStorage.removeItem('redirect_after_login')

                        // Redirect to dashboard
                        navigate(redirectAfterLogin)
                        return;
                    }
                }

                // Redirect to dashboard
                navigate("/dashboard")
            }

            setIsVerifyCodeLoading(false)
        } catch (err: any) {
            if (err?.response == undefined) {
                log("debug", "Failed reaching API while checking for Google login code.", {
                    exception: err,
                })

                setIsVerifyCodeResponseMessage('Tidak dapat menghubungi server, coba lagi');
            } else {
                setIsVerifyCodeResponseMessage(err?.response?.data?.message);
            }

            log("debug", "Gagal login dengan Google.", {
                endpoint: endpoint,
                error: err,
            })

            setIsVerifyCodeLoading(false)
            setIsVerifyCodeSuccess(false)
        }
    }
    // Check once on load
    useEffect(() => {
        // Check for Google callback code in URL
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            const googleCode = urlParams.get('code');
            const googleError = urlParams.get('error');

            // if Error
            if (googleError) {
                navigate("/?error=google_login_failed")
                return;
            }

            if (googleCode) {
                // If code is present, call validate function
                postValidateLoginCode(googleCode);
            } else {
                // If no code, stop loading
                setIsVerifyCodeLoading(false);
            }
        }
    }, []);


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Menyiapkan Halaman ...</title>
            </Helmet>

            <Container className={styles.section}>
                <Row>
                    <Col>
                        <h1>Harap Tunggu Sebentar</h1>
                        <div className={styles.login}>
                            <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spin} />

                            <div>Sedang Mempersiapkan Halaman...</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Callback
