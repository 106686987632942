exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auth-google-callback-index-tsx": () => import("./../../../src/pages/auth/google/callback/index.tsx" /* webpackChunkName: "component---src-pages-auth-google-callback-index-tsx" */),
  "component---src-pages-dashboard-buy-physical-card-tsx": () => import("./../../../src/pages/dashboard/BuyPhysicalCard.tsx" /* webpackChunkName: "component---src-pages-dashboard-buy-physical-card-tsx" */),
  "component---src-pages-dashboard-checkout-detail-content-tsx": () => import("./../../../src/pages/dashboard/CheckoutDetailContent.tsx" /* webpackChunkName: "component---src-pages-dashboard-checkout-detail-content-tsx" */),
  "component---src-pages-dashboard-checkout-history-content-tsx": () => import("./../../../src/pages/dashboard/CheckoutHistoryContent.tsx" /* webpackChunkName: "component---src-pages-dashboard-checkout-history-content-tsx" */),
  "component---src-pages-dashboard-checkout-history-tsx": () => import("./../../../src/pages/dashboard/CheckoutHistory.tsx" /* webpackChunkName: "component---src-pages-dashboard-checkout-history-tsx" */),
  "component---src-pages-dashboard-download-card-tsx": () => import("./../../../src/pages/dashboard/DownloadCard.tsx" /* webpackChunkName: "component---src-pages-dashboard-download-card-tsx" */),
  "component---src-pages-dashboard-id-edit-tsx": () => import("./../../../src/pages/dashboard/IdEdit.tsx" /* webpackChunkName: "component---src-pages-dashboard-id-edit-tsx" */),
  "component---src-pages-dashboard-id-list-tsx": () => import("./../../../src/pages/dashboard/IdList.tsx" /* webpackChunkName: "component---src-pages-dashboard-id-list-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-profile-settings-tsx": () => import("./../../../src/pages/dashboard/ProfileSettings.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-settings-tsx" */),
  "component---src-pages-dashboard-profile-subscription-tsx": () => import("./../../../src/pages/dashboard/ProfileSubscription.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-subscription-tsx" */),
  "component---src-pages-dashboard-test-tsx": () => import("./../../../src/pages/dashboard/Test.tsx" /* webpackChunkName: "component---src-pages-dashboard-test-tsx" */),
  "component---src-pages-distribution-map-tsx": () => import("./../../../src/pages/distribution-map.tsx" /* webpackChunkName: "component---src-pages-distribution-map-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-missing-pets-tsx": () => import("./../../../src/pages/missing-pets.tsx" /* webpackChunkName: "component---src-pages-missing-pets-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-press-kit-tsx": () => import("./../../../src/pages/press-kit.tsx" /* webpackChunkName: "component---src-pages-press-kit-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-pro-features-tsx": () => import("./../../../src/pages/pro-features.tsx" /* webpackChunkName: "component---src-pages-pro-features-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-ruko-kosong-tsx": () => import("./../../../src/pages/ruko-kosong.tsx" /* webpackChunkName: "component---src-pages-ruko-kosong-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

